@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap");
.header {
  padding: 30px;
  position: fixed;
  z-index: 100;
}

.header-row {
  background-color: #fff;
  -webkit-box-shadow: 0px 3px 35px rgba(36, 51, 65, 0.03);
  box-shadow: 0px 3px 35px rgba(36, 51, 65, 0.03);
  border-radius: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px 30px;
  position: relative;
}

.header-search-form {
  width: 60%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.header-search-form::before {
  content: "\e923";
  font-family: "naviconf";
  font-size: 22px;
  margin-right: 15px;
}
.header-search-form input {
  padding: 0;
  border: none;
  width: 100%;
  display: block;
  background-color: #fff;
  font-family: "Montserrat", sans-serif;
  color: #130f26;
  font-size: 14px;
}
.header-search-form ::-webkit-input-placeholder {
  color: #130f26;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.header-search-form ::-moz-placeholder {
  color: #130f26;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.header-search-form :-ms-input-placeholder {
  color: #130f26;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.header-search-form ::-ms-input-placeholder {
  color: #130f26;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.header-search-form ::placeholder {
  color: #130f26;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.right-header-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.account-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}
.account-header .account-header-img {
  border-radius: 50%;
  overflow: hidden;
  margin-right: 15px;
}
.account-header .account-header-name {
  font-weight: bold;
  color: #243341;
}

.account-header-type-change {
  margin-left: 20px;
  margin-right: 35px;
}
.account-header-type-change .account-header-type-change__list {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  padding: 30px 30px;
  -webkit-box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.09);
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.09);
  border-radius: 15px;
  right: 20px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  top: -20px;
  background-color: #fff;
}
.account-header-type-change .account-header-type-change__list a {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 8px;
  color: #243341;
}
.account-header-type-change .account-header-type-change__list li {
  margin-bottom: 15px;
}
.account-header-type-change .account-header-type-change__list li:last-child {
  margin-bottom: 0;
}
.account-header-type-change .account-header-type-change__current {
  font-size: 8px;
  color: #243341;
  font-weight: bold;
  text-transform: uppercase;
  padding: 7px 10px;
  border-radius: 6px;
  border: 1px solid;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.account-header-type-change .account-header-type-change__current::after {
  content: "\e903";
  font-family: "naviconf";
  margin-left: 8px;
}
.account-header-type-change .account-header-type-change__current:hover {
  border-color: #243341;
  background-color: #243341;
  color: #fff;
}
.account-header-type-change
  .account-header-type-change__current.open
  + .account-header-type-change__list {
  opacity: 1;
  visibility: visible;
}

.lang-switcher {
  font-size: 8px;
  color: #243341;
  font-weight: bold;
}
.lang-switcher img {
  border-radius: 3px;
  overflow: hidden;
  margin-right: 10px;
  max-width: 32px;
}
.lang-switcher .lang-switcher__current {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.lang-switcher .lang-switcher__current::after {
  content: "\e903";
  font-family: "naviconf";
  margin-left: 7px;
}
.lang-switcher .lang-switcher__list {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  height: auto;
  background-color: #fff;
  -webkit-box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.09);
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.09);
  top: 0;
  right: 0;
  padding: 30px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  width: 130px;
  border-radius: 15px;
}
.lang-switcher .lang-switcher__list a {
  font-size: 8px;
  color: #243341;
  font-weight: bold;
}
.lang-switcher .lang-switcher__list li {
  margin-bottom: 15px;
}
.lang-switcher .lang-switcher__list li:last-child {
  margin-bottom: 0;
}
.lang-switcher .lang-switcher__current.open + .lang-switcher__list {
  opacity: 1;
  visibility: visible;
}

a {
  text-decoration: none;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  outline: none !important;
}

.header,
article {
  padding-left: 345px;
  width: 100%;
  padding-right: 30px;
}

article {
  padding-top: 140px;
}

.contstructor-page {
  padding-right: 0;
}

.toggle {
  margin-right: 15px;
  cursor: pointer;
}
.toggle span {
  width: 24px;
  height: 2px;
  border-radius: 4px;
  background-color: #fff;
  display: block;
  margin-bottom: 7px;
}
.toggle span:last-child {
  margin-bottom: 0;
}

.sidebar-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 18px;
}

.sidebar nav a {
  color: #fff;
}
.sidebar nav a:hover {
  color: #36b2f1;
}

.icon-small {
  font-size: 20px !important;
}

.sidebar {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.sidebar nav ul .link-toggle-submenu {
  position: relative;
  padding-bottom: 0;
}

.sidebar nav ul .link-toggle-submenu + ul {
  max-height: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.1s;
  -o-transition: opacity 0.1s;
  transition: opacity 0.1s;
}
.sidebar nav ul .link-toggle-submenu.open + ul {
  max-height: 100000px;
  visibility: visible;
  opacity: 1;
}
.sidebar nav ul .link-toggle-submenu.open {
  padding-bottom: 40px;
}

#sidebar-nav {
  height: calc(100vh - 160px);
  overflow: auto;
}

.simplebar-scrollbar::before {
  background-color: #36b2f1;
}

.simplebar-track.simplebar-vertical {
  width: 8px;
  right: 0;
}

body {
  background-color: #f4f6f8;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #243341;
  font-weight: 400;
}

button {
  padding: 0;
  margin: 0;
  background-color: #fff0;
  border: none;
}

.margin-null {
  margin: 0;
}

.breadcrumbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 45px;
}
.breadcrumbs a {
  text-decoration: underline;
  color: #6c7a7d;
  position: relative;
  padding-right: 20px;
}
.breadcrumbs a::after {
  content: "";
  position: absolute;
  width: 0;
  right: 5px;
  top: 5px;
  height: 0;
  border-style: solid;
  border-width: 3px 0 3px 6px;
  border-color: transparent transparent transparent #6c7a7d;
}
.breadcrumbs a:last-child::after {
  display: none;
}

.title-page {
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
}

.tabs-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 15px;
  -webkit-box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  width: 80px;
  
}
.tabs-nav .top-links,
.tabs-nav .bottom-links {
  position: relative;
}
.tabs-nav .top-links li,
.tabs-nav .bottom-links li {
  padding: 20px 17px;
  height: 80px;
  position: relative;
  cursor: pointer;
  z-index: 2;
}
.tabs-nav .top-links a,
.tabs-nav .top-links li,
.tabs-nav .bottom-links a,
.tabs-nav .bottom-links li {
  font-size: 9px;
  font-weight: bold;
  text-align: center;
  color: #243341;
  -webkit-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  -o-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
}
.tabs-nav .top-links a:hover,
.tabs-nav .top-links li:hover,
.tabs-nav .bottom-links a:hover,
.tabs-nav .bottom-links li:hover {
  color: #36b2f1;
}
.tabs-nav .top-links a i,
.tabs-nav .top-links li i,
.tabs-nav .bottom-links a i,
.tabs-nav .bottom-links li i {
  font-size: 22px;
  display: block;
  padding-bottom: 5px;
}
.tabs-nav .top-links a span,
.tabs-nav .top-links li span,
.tabs-nav .bottom-links a span,
.tabs-nav .bottom-links li span {
  display: block;
  text-align: center;
  word-wrap: break-word;
  line-height: 1.2;
}
.tabs-nav .top-links .top-links__active,
.tabs-nav .bottom-links .top-links__active {
  position: absolute;
  top: 0px;
  left: -5px;
  width: 85px;
  height: 80px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  -o-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  border-radius: 15px 0 0 15px;
  z-index: 1;
}
.tabs-nav .top-links .top-links__active::before,
.tabs-nav .top-links .top-links__active::after,
.tabs-nav .bottom-links .top-links__active::before,
.tabs-nav .bottom-links .top-links__active::after {
  content: "";
  width: 23px;
  height: 23px;
  -webkit-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  -o-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;

  background-color: #fff0;
  position: absolute;
  bottom: -23px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  right: 0;
}
.tabs-nav .top-links .top-links__active::before,
.tabs-nav .bottom-links .top-links__active::before {
  top: -23px;
  bottom: auto;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.tabs-nav .top-links .tab-link.active:nth-child(1) ~ .top-links__active,
.tabs-nav .bottom-links .tab-link.active:nth-child(1) ~ .top-links__active {
  top: 0;
}
.tabs-nav .top-links .tab-link.active:nth-child(1) ~ .top-links__active::before,
.tabs-nav
  .bottom-links
  .tab-link.active:nth-child(1)
  ~ .top-links__active::before {
  background-image: none;
  background-color: #fff;
  top: 0;
  right: -15px;
}
.tabs-nav .top-links .tab-link.active:nth-child(2) ~ .top-links__active,
.tabs-nav .bottom-links .tab-link.active:nth-child(2) ~ .top-links__active {
  top: 80px;
}
.tabs-nav .top-links .tab-link.active:nth-child(3) ~ .top-links__active,
.tabs-nav .bottom-links .tab-link.active:nth-child(3) ~ .top-links__active {
  top: 160px;
}
.tabs-nav .bottom-links a i {
  color: #36b2f1;
  font-size: 30px;
}
.tabs-nav .bottom-links a span {
  word-wrap: normal;
}
.tabs-nav .toggle-tabs {
  width: 35px;
  height: 35px;
  border: 1px solid #dbe6ef;
  border-radius: 50%;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  -webkit-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  -o-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 10px;
  color: #243341;
  margin: auto;
}
.tabs-nav .toggle-tabs i {
  position: relative;
}
.tabs-nav .toggle-tabs:hover {
  background-color: #dbe6ef;
}

.constructor-tabs {
  position: relative;
  overflow: hidden;
  /*height: 100vh;*/
}

.constructor-tabs .tabs-content {
  width: 0;
  background-color: #fff;
  border-radius: 15px;
  -webkit-transition: width cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s,
    padding cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  -o-transition: width cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s,
    padding cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  transition: width cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s,
    padding cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;

  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  visibility: hidden;
  position: relative;
  opacity: 0;
}
.constructor-tabs .tabs-content .tab {
  min-width: 230px;
  width: 230px;
  position: absolute;
  top: 30px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  -o-transition: opacity cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  transition: opacity cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
}
.constructor-tabs .tabs-content .tab.active {
  position: relative;
  top: 0;
  opacity: 1;
  visibility: visible;
}
.constructor-tabs.open .top-links__active {
  opacity: 1;
  visibility: visible;
}
.constructor-tabs.open .tabs-content {
  padding: 30px;
  -webkit-transition: width cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s,
    padding cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  -o-transition: width cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s,
    padding cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  transition: width cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s,
    padding cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  width: 360px;
  visibility: visible;
  display: block;
  opacity: 1;
}
.constructor-tabs.open .toggle-tabs i {
  left: 2px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.constructor-tabs.open .tabs-nav {
  border-radius: 15px 0 0 15px;
  -webkit-box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04),
    inset -30px 0 80px rgba(36, 51, 65, 0.08);
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04),
    inset -30px 0 80px rgba(36, 51, 65, 0.08);
}

.search-tab .form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
}
.search-tab .form button {
  color: #36b2f1;
  border: none;
  background-color: #fff0;
}
.search-tab .form button i {
  font-size: 30px;
}
.search-tab .form i {
  font-size: 17px;
  margin-right: 10px;
}
.search-tab .form input {
  border: none;
  background-color: #fff0;
  font-size: 12px;
  color: #130f26;
  line-height: 1.2;
}
.search-tab .form ::-webkit-input-placeholder {
  color: #130f26;
  font-size: 12px;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
}
.search-tab .form ::-moz-placeholder {
  color: #130f26;
  font-size: 12px;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
}
.search-tab .form :-ms-input-placeholder {
  color: #130f26;
  font-size: 12px;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
}
.search-tab .form ::-ms-input-placeholder {
  color: #130f26;
  font-size: 12px;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
}
.search-tab .form ::placeholder {
  color: #130f26;
  font-size: 12px;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
}

.list-tab {
  margin-top: 30px;
}
.list-tab .item-list {
  border: 1px solid #daebef;
  background: #f4f6f8;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 8px 10px;
  color: #243341;
  font-size: 10px;
  font-weight: 700;
  margin-bottom: 4px;
  cursor: pointer;
  line-height: 14px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  display: block;
}
.list-tab .item-list:last-child {
  margin-bottom: 0;
}
.list-tab .item-list:hover {
  color: #36b2f1;
}

.head-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.head-title-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-start;
}

.filter-date {
  padding-right: 170px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.filter-date .title-date {
  padding: 0 60px;
  font-size: 22px;
  font-weight: bold;
}
.filter-date .title-date i {
  margin-right: 15px;
}
.filter-date .filter-arrows {
  padding: 0;
  border: none;
  background-color: #fff0;
  font-size: 18px;
}

.constructor-row {
  padding-bottom: 1px;

  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  min-height: 100%;

  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.constructor-row .constructor-table {
  width: calc(100% - 100px);
  margin-right: 20px;
  transition: 0.2s;
}

.contstructor-page.open-tabs .constructor-table {
  width: calc(100% - 350px);
}

.constructor-table .line-constructor.title-constructor .move {
  background-image: url("../img/move.svg");
  background-position: center;
  cursor: pointer;
  background-repeat: no-repeat;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.constructor-row .constructor-tabs {
  width: auto;
  max-width: 370px;
  margin-top: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.constructor-table .table {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  margin-top: 30px;
  padding-bottom: 5px;
  overflow: auto;
  height: 100vh;
}

.constructor-table .body-contstructor {
  overflow: auto;
}
.constructor-table .head-contstructor,
.constructor-table .line-constructor {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.constructor-table .head-contstructor .time,
.constructor-table .line-constructor .time {
  width: 120px;
  max-width: 120px;
  min-width: 120px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.constructor-table .head-contstructor .move,
.constructor-table .line-constructor .move {
  width: 30px;
  min-width: 30px;
  background-color: #f4f6f8;
}
.constructor-table .head-contstructor .duration,
.constructor-table .line-constructor .duration {
  width: 10%;
}
.constructor-table .head-contstructor .theme,
.constructor-table .line-constructor .theme {
  width: 30%;
}
.constructor-table .head-contstructor .speaker,
.constructor-table .line-constructor .speaker {
  width: 30%;
}
.constructor-table .head-contstructor .room,
.constructor-table .line-constructor .room {
  width: 15%;
}
.constructor-table .head-contstructor .options,
.constructor-table .line-constructor .options {
  max-width: 50px;
  width: 50px;
}
.constructor-table .line-constructor {
  border-bottom: 1px solid #daebef;
}
.constructor-table .line-constructor .item-add {
  font-size: 12px;
  padding-left: 10px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 15px;
  line-height: 15px;
  color: #6c7a7d;
  cursor: pointer;
  font-weight: 400;
  position: relative;
}
.constructor-table .line-constructor .item-add::after {
  content: "\e903";
  font-family: "naviconf";
  font-size: 10px;
  color: #243341;
  position: absolute;
  right: 16px;
}
.constructor-table .line-constructor > div {
  padding: 5px;
}
.constructor-table .line-constructor .theme {
  border-left: 1px solid #daebef;
  border-right: 1px solid #daebef;
}
.constructor-table .line-constructor .speaker {
  border-right: 1px solid #daebef;
}
.constructor-table .line-constructor .room {
  border-right: 1px solid #daebef;
}
.constructor-table .line-constructor .time {
  padding-top: 10px;
  text-align: center;
}
.constructor-table .line-constructor .name-parent {
  padding: 10px 0;
  width: calc(100% - 150px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.constructor-table .line-constructor .name-parent .left-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}
.constructor-table
  .line-constructor
  .name-parent
  .left-name:hover
  .collapse-icon {
  background-color: #dbe6ef;
}
.constructor-table .line-constructor .name-parent .add-theme-button {
  min-width: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 11px;
  cursor: pointer;
}
.constructor-table .line-constructor .name-parent .add-theme-button i {
  font-size: 30px;
  margin-right: 10px;
  color: #36b2f1;
}
.constructor-table .line-constructor .name-parent .add-theme-button span {
  display: block;
  text-align: center;
  max-width: 50px;
  font-size: 9px;
  -webkit-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  -o-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  line-height: 12px;
}
.constructor-table .line-constructor .name-parent .add-theme-button:hover span {
  color: #36b2f1;
}
.constructor-table .line-constructor .name-parent .collapse-icon {
  width: 35px;
  height: 35px;
  border: 1px solid #dbe6ef;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  font-size: 8px;
  -webkit-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  -o-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  margin-right: 15px;
  margin-left: 20px;
}
.constructor-table .line-constructor.title-constructor {
  border-bottom: 1px solid #daebef;
}
.constructor-table .line-constructor.title-constructor .move {
  background-position: center;
  cursor: pointer;
  background-repeat: no-repeat;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.constructor-table .line-constructor.title-constructor .move:hover {
  background-color: #e9ecee;
}
.constructor-table .line-constructor.title-constructor .collapse-icon i {
  position: relative;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.constructor-table .line-constructor.title-constructor.open .collapse-icon i {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}
.constructor-table .line-constructor .options-button {
  text-align: center;
  color: #a9bbcb;
  font-size: 20px;
  height: 100%;
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  -o-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}
.constructor-table .line-constructor .options-button:hover {
  color: #243341;
}
.constructor-table .head-contstructor {
  padding: 23px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  border-bottom: 1px solid #dbe6ef;
}
.constructor-table .head-contstructor .theme {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.constructor-table .head-contstructor .time {
  padding-left: 30px;
}
.constructor-table .head-contstructor .duration {
  padding-left: 40px;
  width: calc(13% + 40px);
}
.constructor-table .head-contstructor .speaker {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.constructor-table .head-contstructor .room {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.constructor-table .head-contstructor .time {
  width: 12.5%;
}
.constructor-table .head-contstructor .room {
  width: 26.5%;
}
.constructor-table .head-contstructor .col-name {
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin-right: 20px;
  padding-right: 15px;
}
.constructor-table .head-contstructor .col-name::after,
.constructor-table .head-contstructor .col-name::before {
  content: "";
  width: 0;
  position: absolute;
  right: 0px;
  bottom: 0px;
  height: 0;
  border-style: solid;
  border-width: 6px 3px 0 3px;
  border-color: #243341 transparent transparent;
}
.constructor-table .head-contstructor .col-name::before {
  bottom: auto;
  top: 0px;
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.constructor-table .head-contstructor .search-col form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.constructor-table .head-contstructor .search-col form i {
  font-size: 17px;
  margin-right: 10px;
}
.constructor-table .head-contstructor .search-col form input {
  border: none;
  background-color: #fff0;
  font-size: 12px;
  padding: 0;
  width: 100%;
  color: #130f26;
  line-height: 1.2;
}
.constructor-table
  .head-contstructor
  .search-col
  form
  ::-webkit-input-placeholder {
  color: #130f26;
  font-size: 12px;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
}
.constructor-table .head-contstructor .search-col form ::-moz-placeholder {
  color: #130f26;
  font-size: 12px;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
}
.constructor-table .head-contstructor .search-col form :-ms-input-placeholder {
  color: #130f26;
  font-size: 12px;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
}
.constructor-table .head-contstructor .search-col form ::-ms-input-placeholder {
  color: #130f26;
  font-size: 12px;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
}
.constructor-table .head-contstructor .search-col form ::placeholder {
  color: #130f26;
  font-size: 12px;
  opacity: 1;
  font-family: "Montserrat", sans-serif;
}
.constructor-table .line-constructor .time .time-value {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15em;
  text-align: center;
  padding-bottom: 5px;
  display: block;
}
.constructor-table .line-constructor .time .select-format {
  background-color: #24d8c2;
  color: #fff;
  border-radius: 6px;
  padding: 5px;
  text-transform: uppercase;
  font-size: 8px;
  cursor: pointer;
  display: inline-block;
  line-height: 10px;
  letter-spacing: 0.01em;
  position: relative;
  font-weight: 700;
}

.constructor-table .line-constructor .time .select-format.hybrid {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#3c4eed),
    to(#24d8c2)
  );
  background: -o-linear-gradient(left, #3c4eed 0%, #24d8c2 100%);
  background: linear-gradient(90deg, #3c4eed 0%, #24d8c2 100%);
}
.constructor-table .line-constructor .time .select-format.offline {
  background-color: #3c4eed;
}
.constructor-table .line-constructor .theme-item,
.constructor-table .line-constructor .room-item {
  border: 1px solid #daebef;
  background: #f4f6f8;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 8px 10px;
  color: #243341;
  font-size: 12px;
  padding-right: 20px;
  font-weight: 700;
  height: 100%;
  cursor: pointer;
  line-height: 14px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  display: block;
  position: relative;
}
.constructor-table .line-constructor .theme-item::after,
.constructor-table .line-constructor .room-item::after {
  content: "\e903";
  font-family: "naviconf";
  font-size: 10px;
  color: #243341;
  position: absolute;
  right: 15px;
  font-weight: 100;
  top: 20px;
}
.constructor-table .line-constructor .room-item {
  padding: 12px;
  padding-right: 25px;
}
.constructor-table .line-constructor .room-item::after {
  top: 10px;
  right: 15px;
}
.constructor-table .line-constructor .speakers-list li {
  border: 1px solid #daebef;
  background: #f4f6f8;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 8px 10px;
  color: #243341;
  font-size: 12px;
  padding-left: 20px;
  padding-right: 17px;
  position: relative;
  font-weight: 700;
  margin-bottom: 4px;
  cursor: pointer;
  line-height: 14px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.constructor-table .line-constructor .speakers-list li:last-child {
  margin-bottom: 0;
}
.constructor-table .line-constructor .speakers-list li .status-speaker {
  padding-top: 5px;
  display: block;
  font-weight: 600;
  position: relative;
}
.constructor-table .line-constructor .speakers-list li .status-speaker::after {
  content: "\e903";
  font-family: "naviconf";
  font-size: 7px;
  color: #243341;
  position: absolute;
  right: 0px;
  font-weight: 100;
}
.constructor-table .line-constructor .speakers-list li .status-speaker b {
  font-weight: 700;
  padding-left: 10px;
}
.constructor-table .line-constructor .speakers-list li .status-speaker .yellow {
  color: #ff8f0c;
}
.constructor-table .line-constructor .speakers-list li .status-speaker .green {
  color: #2fdd99;
}
.constructor-table .line-constructor .speakers-list li .status-lock {
  width: 20px;
  background-color: #fff;
  height: 21px;
  display: inline-block;
  font-size: 8px;
  color: #6c7a7d;
  border-top: 1px solid #daebef;
  border-bottom: 1px solid #daebef;
  position: absolute;
  border-radius: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  margin: auto;
  left: -16px;
}
.constructor-table .line-constructor .speakers-list li .status-lock i {
  position: relative;
  z-index: 1;
}
.constructor-table .line-constructor .speakers-list li .status-lock::before,
.constructor-table .line-constructor .speakers-list li .status-lock::after {
  content: "";
  width: 13px;
  height: 13px;
  position: absolute;
  border-left: 1px solid #daebef;
  border-bottom: 1px solid #daebef;
  border-radius: 2px;
  -webkit-transform: rotate(45deg) skew(10deg, 10deg);
  -ms-transform: rotate(45deg) skew(10deg, 10deg);
  transform: rotate(45deg) skew(10deg, 10deg);
  background-color: #fff;
  top: 3px;
  left: -6px;
  z-index: 0;
}
.constructor-table .line-constructor .speakers-list li .status-lock::after {
  left: auto;
  right: -6px;
  -webkit-transform: rotate(225deg) skew(10deg, 10deg);
  -ms-transform: rotate(225deg) skew(10deg, 10deg);
  transform: rotate(225deg) skew(10deg, 10deg);
}
.constructor-table
  .line-constructor
  .speakers-list
  li
  .status-lock.status-green {
  border-color: #2fdd99;
  background-color: #2fdd99;
  color: #fff;
}
.constructor-table
  .line-constructor
  .speakers-list
  li
  .status-lock.status-green::after,
.constructor-table
  .line-constructor
  .speakers-list
  li
  .status-lock.status-green::before {
  border-color: #2fdd99;
  background-color: #2fdd99;
}
.constructor-table .line-constructor .duration-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.constructor-table .line-constructor .duration-item label {
  width: 50px;
  position: relative;
}
.constructor-table .line-constructor .duration-item label span {
  position: absolute;
  bottom: -15px;
  display: block;
  text-align: center;
  width: 100%;
  left: 0;
  color: #6c7a7d;
  font-size: 12px;
}
.constructor-table .line-constructor .duration-item input {
  border: none;
  width: 100%;
  padding: 0;
  font-size: 14px;
  text-align: center;
  font-weight: 300;
}
.constructor-table .line-constructor .duration-item .pluse,
.constructor-table .line-constructor .duration-item .minus {
  cursor: pointer;
}

.list-theme {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  -o-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
}

.h1 {
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;
}

.conf-detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.conf-detail .conf-info .title {
  margin-bottom: 30px;
}
.conf-detail .conf-info .tags {
  margin-bottom: 25px;
}
.conf-detail .conf-info .tags li {
  display: inline-block;
  padding: 6px;
  border-radius: 6px;
  background-color: #d5dee8;
  text-transform: uppercase;
  font-size: 8px;
  line-height: 10px;
  letter-spacing: 0.1em;
  font-weight: 700;
  margin-right: 10px;
}
.conf-detail .conf-info .tags li:last-child {
  margin-right: 0;
}
.conf-detail .conf-info .meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.conf-detail .conf-info .meta .date,
.conf-detail .conf-info .meta .address {
  border-radius: 10px;
  border: 1px solid #daebef;
  padding: 14px 20px;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
}
.conf-detail .conf-info .meta .date i,
.conf-detail .conf-info .meta .address i {
  font-size: 25px;
  margin-right: 20px;
}
.conf-detail .conf-info .meta .date span,
.conf-detail .conf-info .meta .address span {
  display: block;
}
.conf-detail .conf-info .meta .date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 10px;
}
.conf-detail .conf-info .meta .date .day {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 30px;
  margin-right: 30px;
  position: relative;
}
.conf-detail .conf-info .meta .date .day::after {
  content: "";
  width: 1px;
  height: 34px;
  background-color: #a9bbcb;
  opacity: 0.2;
  position: absolute;
  right: 0;
}
.conf-detail .conf-info .meta .address {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 100px;
}

.conf-links {
  margin-top: 20px;
  margin-left: 120px;
}
.conf-links .btn-icon {
  margin-bottom: 45px;
  min-width: 280px;
}
.conf-links .btn-icon:last-child {
  margin-bottom: 0;
}

.tabs-setting-conf-contents {
  padding: 45px 30px;
  background-color: #fff;
  border-radius: 0 0px 20px 20px;
  -webkit-box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  margin-bottom: 20px;
}

.tabs-setting-conf {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.tabs-setting-conf .item {
  width: calc(25% - 20px);
  border-radius: 15px 15px 0 0;
  border: 1px solid #fff;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    color-stop(1.13%, #f4f6f8),
    color-stop(98.87%, #eff2f4)
  );
  background: -o-linear-gradient(right, #f4f6f8 1.13%, #eff2f4 98.87%);
  background: linear-gradient(270deg, #f4f6f8 1.13%, #eff2f4 98.87%);
  padding: 15px 20px;
  padding-right: 50px;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.tabs-setting-conf .item .icon-tab {
  width: 35px;
  height: 35px;
  color: #fff;
  font-size: 19px;
  background: #24d8c2;
  border-radius: 9px;
  margin-right: 15px;
  min-width: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.tabs-setting-conf .item .icon-tab.offline {
  background: #3c4eed;
}
.tabs-setting-conf .item .icon-tab.gybrid {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#3c4eed),
    to(#24d8c2)
  );
  background: -o-linear-gradient(left, #3c4eed 0%, #24d8c2 100%);
  background: linear-gradient(90deg, #3c4eed 0%, #24d8c2 100%);
}
.tabs-setting-conf .item.active {
  padding-top: 25px;
  background: #fff;
}
.tabs-setting-conf .item .close-tab {
  margin-left: 20px;
  font-size: 13px;
  position: absolute;
  right: 20px;
  color: #a9bbcb;
  cursor: pointer;
}
.tabs-setting-conf .item .close-tab:hover {
  color: #243341;
}
.tabs-setting-conf .item-options {
  width: 80px;
  padding: 22.5px;
  font-size: 20px;
  color: #a9bbcb;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
  color: #243341;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.05em;
}
.btn-icon i {
  font-size: 60px;
  margin-right: 25px;
}
.btn-icon:hover {
  color: #36b2f1;
}

.block {
  background: #fff;
  -webkit-box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  border-radius: 15px;
}
.block .header-block {
  padding: 30px;
  border-bottom: 1px solid #dbe6ef;
}
.block .header-block .title {
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
}
.block .content-block {
  padding: 30px;
}

.settings-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.settings-block .item {
  width: calc(33% - 40px);
}
.settings-block .item .title {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 20px;
}

.sub-title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}

textarea {
  width: 100%;
  height: 140px;
  border: 1px solid #a9bbcb;
  border-radius: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 15px;
  font-family: "Montserrat", sans-serif;
  resize: none;
  color: #6c7a7d;
}

::-webkit-input-placeholder {
  color: #6c7a7d;
  opacity: 1;
}

::-moz-placeholder {
  color: #6c7a7d;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #6c7a7d;
  opacity: 1;
}

::-ms-input-placeholder {
  color: #6c7a7d;
  opacity: 1;
}

::placeholder {
  color: #6c7a7d;
  opacity: 1;
}

.file-label {
  width: 100%;
  height: 250px;
  border-radius: 10px;
  border: 1px dashed #dbe6ef;
  background: #f9fafb;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  margin-bottom: 20px;
}
.file-label .edit-btns {
  position: absolute;
  right: 8px;
  top: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.file-label .edit-btns .edit,
.file-label .edit-btns .delete {
  background: #ffffff;
  border: 1px solid #daebef;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  width: 32px;
  cursor: pointer;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 12px;
  -webkit-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  -o-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
}
.file-label .edit-btns .edit:hover,
.file-label .edit-btns .delete:hover {
  background: #daebef;
}
.file-label .edit-btns .edit {
  margin-right: 10px;
}
.file-label .edit-btns .delete {
  color: #ff5c5c;
}
.file-label label {
  color: #36b2f1;
  position: relative;
  cursor: pointer;
  line-height: 17px;
  display: block;
  text-align: center;
  margin-bottom: 10px;
}
.file-label label i {
  display: block;
  font-size: 70px;
  margin-bottom: 20px;
}
.file-label label span {
  text-decoration: underline;
}
.file-label .desc-file {
  font-size: 12px;
  color: #6c7a7d;
  display: block;
  text-align: center;
}
.file-label input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
}

.registration-row .title {
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 20px;
}

.block .registration-row {
  padding: 30px 0;
}

.registration-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.registration-row .width-1-3 {
  width: calc(33% - 60px);
  margin: 0 30px;
}
.registration-row .width-2-3 {
  width: calc(67% - 60px);
  margin: 0 30px;
}

.checkbox-input {
  position: relative;
  cursor: pointer;
}
.checkbox-input span {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 400;
}
.checkbox-input span::before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 5px;
  border: 1px solid #a9bbcb;
  background-color: #fff;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  position: relative;
  margin-right: 10px;
}
.checkbox-input span::after {
  content: "\e90a";
  font-family: "naviconf";
  font-size: 10px;
  color: #fff;
  opacity: 0;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  position: absolute;
  left: 4px;
  font-weight: 400;
}
.checkbox-input input {
  position: absolute;
  left: 0;
  visibility: hidden;
}
.checkbox-input input:checked + span::before {
  background-color: #33afee;
  border-color: #33afee;
}
.checkbox-input input:checked + span::after {
  opacity: 1;
}

.row-sb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.lines-registration .item {
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f4f6f8;
  border: 1px solid #daebef;
  border-radius: 10px;
  position: relative;
  margin-bottom: 5px;
}
.lines-registration .item:last-child {
  margin-bottom: 0;
}
.lines-registration .item::before {
  content: "";
  width: 10px;
  cursor: pointer;
  min-width: 10px;
  margin-right: 10px;
  height: 18px;
}
.lines-registration .delete {
  color: #ff5c5c;
  font-size: 30px;
  cursor: pointer;
}
.lines-registration .checkbox-input {
  min-width: 270px;
  margin-left: 10px;
}
.lines-registration input {
  width: 100%;
  margin: 0 10px;
}

input {
  padding: 15px;
  color: #243341;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #a9bbcb;
  font-family: "Montserrat", sans-serif;
}

.registration-row .width-1-3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
}
.registration-row .width-1-3 textarea {
  height: 100%;
}

.link-addline {
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  margin-top: 20px;
  width: 150px;
  margin-right: 0;
  margin-left: auto;
  font-family: "Montserrat", sans-serif;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff0;
}
.link-addline i {
  color: #36b2f1;
  font-size: 30px;
  margin-right: 10px;
}
.link-addline span {
  text-decoration: underline;
  color: #36b2f1;
  font-weight: 400;
}

.btn-border {
  padding: 15px 25px;
  border: 1px solid #243341;
  color: #243341;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  text-transform: uppercase;
  -webkit-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  -o-transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
  transition: cubic-bezier(0.65, 0.05, 0.36, 1) 0.2s;
}
.btn-border i {
  font-size: 20px;
  margin-right: 15px;
}
.btn-border:hover {
  color: #fff;
  background: #243341;
}

.btn-member {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 25px;
}
.btn-member a {
  margin-right: 15px;
}
.btn-member .btn-gradient {
  padding: 20px 65px;
}

.btn-gradient {
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    color-stop(-2.55%, #1994d2),
    color-stop(104.08%, #36b2f1)
  );
  background: -o-linear-gradient(right, #1994d2 -2.55%, #36b2f1 104.08%);
  background: linear-gradient(270deg, #1994d2 -2.55%, #36b2f1 104.08%);
  border-radius: 10px;
  text-align: center;
  padding: 20px 30px;
}

.block-default {
  background-color: #fff;
  margin-top: 20px;
  padding: 30px;
  border-radius: 15px;
  -webkit-box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
  box-shadow: 0px 3px 20px rgba(36, 51, 65, 0.04);
}
.block-default .title {
  font-size: 22px;
  line-height: 27px;
  font-weight: 600;
  margin-bottom: 20px;
}
.block-default p {
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 20px;
}
.block-default .title-small {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 20px;
}

.partners ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.partners ul li {
  margin-right: 65px;
}
.partners ul li:last-child {
  margin-right: 0;
}

.navigation-desc {
  margin-bottom: 30px;
}
.navigation-desc img {
  border-radius: 10px;
  width: 100%;
}
.navigation-desc .row {
  margin-bottom: 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.navigation-desc .row:last-child {
  margin-bottom: 0;
}
.navigation-desc .title {
  margin-bottom: 50px;
}

.tab-switcher {
  border: 1px solid #1994d2;
  border-radius: 10px;
  margin-bottom: 40px;
  display: inline-block;
}
.tab-switcher ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.tab-switcher ul li {
  padding: 16px 27px;
  border-radius: 9px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 12px;
  background-color: #fff;
}
.tab-switcher ul li.active {
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    color-stop(-2.55%, #1994d2),
    color-stop(104.08%, #36b2f1)
  );
  background: -o-linear-gradient(right, #1994d2 -2.55%, #36b2f1 104.08%);
  background: linear-gradient(270deg, #1994d2 -2.55%, #36b2f1 104.08%);
  color: #fff;
}

.list-schedule .item {
  border-radius: 10px;
  border: 1px solid #daebef;
  background-color: #f4f6f8;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}
.list-schedule .item:last-child {
  margin-bottom: 0;
}
.list-schedule .item .time {
  font-size: 18px;
  line-height: 22px;
  font-weight: bold;
  letter-spacing: 0.15em;
  padding-right: 20px;
  margin-right: 20px;
  position: relative;
}
.list-schedule .item .time::after {
  content: "";
  width: 1px;
  height: calc(100% + 20px);
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: #daebef;
  position: absolute;
  right: 0;
}
.list-schedule .item.inactive {
  color: #6c7a7d;
}
.list-schedule .item .theme {
  width: 100%;
}
.list-schedule .item .title-theme {
  font-size: 12px;
  line-height: 15px;
  font-weight: 700;
  padding-bottom: 5px;
}
.list-schedule .item .desc-theme {
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
}
.list-schedule .item .favorite {
  width: 30px;
  min-width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
.list-schedule .item .favorite::before {
  font-size: 30px;
  font-family: "naviconf";
}
.list-schedule .item .favorite path {
  fill: #fff0;
}
.list-schedule .item .favorite.active path {
  fill: #faff00;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.option-icons svg {
  font-size: 15px;
}

.option-icons {
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
}

.calendar__body-block {
  position: relative;
}

.option-icons > div:first-child {
  margin-right: 5px;
}

.constructor-table .line-constructor .theme-item::after,
.constructor-table .line-constructor .room-item::after,
.calendar-block__col::before,
.constructor-table .line-constructor .speakers-list li .status-speaker::after {
  display: none;
}

.header__form-search i {
  font-size: 22px;
}

.header__form-input {
  padding: 0;
  border: none;
  width: 100%;
  display: block;
  background-color: #fff;
  font-family: "Montserrat", sans-serif;
  color: #130f26;
  font-size: 14px;
}

.header__blocks {
  background-color: #fff;
  -webkit-box-shadow: 0px 3px 35px rgba(36, 51, 65, 0.03);
  box-shadow: 0px 3px 35px rgba(36, 51, 65, 0.03);
  border-radius: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px 30px;
  position: relative;
  height: auto;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.header__form {
  padding-left: 0;
}

.speakers-list li {
  display: flex;
}

.speakers-list .calendar-block__select {
  width: 100%;
}

.status-speaker {
  max-width: 172px;
  box-sizing: border-box;
}

.constructor-table .line-constructor .speakers-list li {
  padding-left: 5px;
  padding-right: 5px;
}

.constructor-table .line-constructor .speakers-list li .status-speaker {
  padding-right: 0;
}

.speaker-choose {
  min-width: calc(100% - 160px);
}

.addSpeaker {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 17px;
  cursor: pointer;
  color: #33afee;
}
